import React from 'react';
import Navbar from "../components/Navbar";
import Footer from "../components/footer";

const HomePageLayout = ({props, children}) => {
    return (
        <>
            <Navbar/>
            {children}
            <Footer/>
        </>
    );
};

export default HomePageLayout;