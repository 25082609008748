import React from 'react';
import image from '../assets/image/image (3).png'
import regi from '../assets/image/4 518.png'
import img5 from '../assets/image/5.png'
import img6 from '../assets/image/6 321970543.png'
import img7 from '../assets/image/7 7.png'
import CourseCard from "./courseCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"
const Training = () => {
   const courses = [
       {
           img:'https://nearlearn.com/public/images/ai-training-in-bangalore.jpeg',
           title:'AI/ML',
       }, {
           img:'https://nearlearn.com/public/images/ai-training-in-bangalore.jpeg',
           title:'Artificial Intelligence',
       }, {
           img:'https://nearlearn.com/public/images/ai-training-in-bangalore.jpeg',
           title:'Artificial Intelligence',
       }, {
           img:'https://i0.wp.com/picjumbo.com/wp-content/uploads/amazing-stone-path-in-forest-free-image.jpg',
           title:'AI/ML',
       }, {
           img:'https://fps.cdnpk.net/images/home/subhome-ai.webp',
           title:'Artificial Intelligence',
       }, {
           img:'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_640.jpg',
           title:'Artificial Intelligence',
       },
   ]

    let settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 1000,
        pauseOnHover: true,
        cssEase: "linear",
        slidesToShow: 3,
        slidesToScroll: 1,

    }
    return (
        <>
            <div className='flex flex-col '>
                <div className='relative flex justify-center items-center '>
                    <img src={image} alt='box' className='w-full h-[500px] object-cover '/>
                    <div className='absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 '/>
                    <h1 className=' absolute text-balance text-center text-white text-5xl align-middle font-bold font-montserrat  '>
                        Training & Internship Programs
                    </h1>
                    <p className='absolute top-80 w-[900px] font-medium font-montserrat text-3xl text-center text-white' >
                        Empowering the next generation with hands-on experience and guaranteed internships.
                    </p>
                </div>
                <div className='container mx-auto px-4 py-10' >
                    <h3 className='text-5xl font-semibold font-montserrat mt-10 mb-10 '> Courses offered </h3>
                    <div className='my-5 px-16 py-16 border border-gray-300 rounded-3xl shadow-lg ' >
                        <Slider  {...settings} className='w-auto'>

                            {courses.map((course, index) => (
                                <CourseCard key={index} courses={course} />
                            ))}
                        </Slider>
                    </div>

                    <div className='mt-10 py-10'>
                        <h1 className='text-5xl font-semibold font-montserrat text-center'>
                            How It Works
                        </h1>
                        <div className='flex justify-around gap-20 items-center mt-10'>
                            <div className='flex flex-col justify-center items-center ' >
                                <img src={regi} alt='register' className='' />
                                <h1 className='text-3xl font-bold text-center font-montserrat'>Register</h1>
                                <p className='text-xl text-center font-montserrat '>Simple sign-up process.</p>
                            </div>
                            <div className='flex flex-col justify-center items-center w-72' >
                                <img src={img5} alt='register' className='' />
                                <h6 className='text-3xl font-bold text-center font-montserrat py-2'>Course Selection</h6>
                                <p className='text-xl text-center font-montserrat '>Choose the right course with guidance from career counselors.</p>
                            </div>
                            <div className='flex flex-col justify-center items-center' >
                                <img src={img6} alt='register' className='' />
                                <h1 className='text-3xl font-bold text-center font-montserrat'>Mentorship</h1>
                                <p className='text-xl text-center font-montserrat '> Learn from industry experts.</p>
                            </div>
                            <div className='flex flex-col justify-center items-center w-72 '>
                                <img src={img7} alt='register' className=''/>
                                <h1 className='text-3xl font-bold text-center font-montserrat'>Internship & Placement </h1>
                                <p className='text-xl text-center font-montserrat '>Guaranteed internship and placement support.</p>
                            </div>
                        </div>

                        <div className='flex justify-center items-center my-20' >
                            <button className='bg-flight-500 text-black  px-5 py-5 text-center rounded-xl  text-2xl font-montserrat font-bold '>
                             Join Our Program and Succeed
                            </button>
                        </div>

                    </div>

                </div>
            </div>
        </>
    );
};

export default Training;