import React from 'react';
import HomePageLayout from "../layout/HomePage.Layout";

const HomeHoc = ( {component: Component} ) => {
    return (
        <>
            <HomePageLayout>
                <Component/>
            </HomePageLayout>
        </>
    );
};

export default HomeHoc;