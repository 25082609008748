import React from 'react';
import photo1 from '../assets/image/3.svg'
import photo2 from '../assets/image/2.svg'
import photo3 from '../assets/image/4.svg'
import {WandSparkles} from 'lucide-react';
import bar from '../assets/image/div.top-0.png'

const ChooseUs = () => {
    return (
        <>
            <div className='font-montserrat flex flex-col container mx-auto p-10'>
                <div className='p-10'>
                    <h1 className='font-bold text-4xl text-center  '>Why Choose Us</h1>
                </div>
                <div className='flex flex-col lg:flex-row justify-center   lg:gap-14  p-5'>
                    <div className='flex flex-col justify-center items-center lg:p-10 max-w-[400px] '>
                        <h2 className='font-semibold text-center text-2xl'>100% internship guarantee</h2>
                        <img src={photo1} alt='100% gurantee' className='flex justify-center w-52 '/>
                        <div className='md:hidden mb-2'>
                            <img src={bar} alt='bar' className='w-32'/>
                        </div>

                    </div>
                    <div className='flex flex-col items-center lg:p-10 max-w-[400px] '>
                        <h2 className='font-semibold text-center text-2xl'>Industry-relevant skills</h2>
                        <img src={photo2} alt='skill' className='flex justify-center w-52'/>
                        <div className='md:hidden mb-2'>
                            <img src={bar} alt='bar' className='w-32'/>
                        </div>
                    </div>
                    <div className='flex flex-col items-center lg:p-10 max-w-[400px] '>
                        <h2 className='font-semibold text-center text-2xl '>Partnership with global tech leaders</h2>
                        <img src={photo3} alt='leaders' className='flex justify-center w-52'/>
                        <div className='md:hidden mb-2'>
                            <img src={bar} alt='bar' className='w-32'/>
                        </div>
                    </div>
                </div>
                <div className=' flex items-center justify-center  '>
                    <div
                        className=' drop-shadow-2xl text-white flex justify-center items-center text-center gap-5 bg-flight-500 lg:px-14 lg:py-6 px-3 py-2  rounded-2xl shadow-flight-500 shadow border-2 border-flight-500 '>
                        <WandSparkles/>
                        <button className=' text-white font-semibold text-lg '>Discover Our Unique Approach</button>
                    </div>
                </div>
            </div>

            <div className='flex justify-around items-center mt-10 p-10 bg-[#FCC509]/55  h-32 md:h-48'>
                <p className='hidden md:flex text-center font-montserrat font-extrabold text-3xl text-white'>
                    Join us, learn, and grow with <span className='text-flight-500'>Digimation Flight!</span>
                </p>

                <button  className='bg-flight-500 px-5 py-3 font-montserrat font-semibold text-lg text-center rounded-lg hover:bg-flight-600/55 transition-opacity duration-300'>
                   <a href='/training' >View All Courses</a>
                </button>
            </div>
        </>
    );
};

export default ChooseUs;