import React from 'react';
import logo from '../assets/image/Logo.png'
import {FaInstagram, FaLinkedin, FaWhatsapp} from "react-icons/fa";
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <>

            <div className=" hidden md:block relative h-full w-full bg-yellow-950 ">

                <div className=' border-t border-gray-800 bg-black p-10'>
                    <div className=' container mx-auto my-10 '>
                        <div className='flex justify-around gap-5 mb-20'>
                            <div className=' flex items-center  flex-col gap-20'>
                                <div className='flex items-center justify-center gap-5 '>
                                    <img src={logo} alt='logo' className='w-20'/>
                                    <h1 className='text-white font-bold text-3xl font-[inter]'>Digimation Flight</h1>
                                </div>
                                <div className='flex gap-5 items-end'>
                                    <a href='https://www.instagram.com/digimationflight'
                                    target="_self"
                                    >
                                        <FaInstagram className='text-slate-300 w-8 h-8 '/>
                                    </a>
                                    <a href='https://www.linkedin.com/company/digimationflight/'
                                       target='_self'>
                                        <FaLinkedin className='text-slate-300 w-8 h-8 '/>
                                    </a>
                                    <a href='https://wa.me/917017768577' target='_self'>
                                        <FaWhatsapp className='text-slate-300 w-8 h-8 '/>
                                    </a>
                                </div>
                            </div>
                            <div className='flex justify-between gap-20  '>
                                <div className='flex flex-col gap-5 text-left px-16 '>
                                    <h3 className='text-white z1 text-xl font-medium'>Quick Links</h3>
                                    <Link to='/' className='text-slate-300 text-lg'>Home </Link>
                                    <Link to='/about' className='text-slate-300 text-lg'>About </Link>
                                    <Link to='/service' className='text-slate-300 text-lg'>Service</Link>
                                    <Link to='/blogs' className='text-slate-300 text-lg'>Blog</Link>
                                    <Link to='/contact' className='text-slate-300 text-lg'>contact</Link>
                                </div>
                                <div className='flex flex-col gap-5 text-left px-16 '>
                                    <h3 className='text-white z1 text-xl font-medium'>Contact Information</h3>
                                    <p className='text-slate-300 text-lg'> +91 70177 68577 </p>
                                    <p className='text-slate-300 text-lg'> info@digimation.com </p>
                                </div>

                            </div>
                        </div>
                        <div className='border-b border-gray-800 my-10'></div>


                        <div className='flex justify-between items-center'>
                            <div className='flex flex-col  '>
                                <h1 className='text-white text-2xl font-medium '>Join Our Newsletter</h1>
                                <p className='text-slate-300 text-md px-5'>Keep up to date with everything Digimation
                                    Flight.</p>
                            </div>

                            <div className='flex justify-center items-center gap-5 '>
                                <form className="flex space-x-2">
                                    <input
                                        type="email"

                                        placeholder="Enter your email"
                                        className=" flex-grow w-80 px-3 py-2 rounded-md bg-transparent text-gray-300 placeholder-gray-500 border border-gray-800 focus:outline-none focus:border-gray-700"
                                        required
                                    />
                                    <button
                                        type="submit"
                                        className="px-4 py-2 rounded-md text-gray-300
                     bg-[rgba(255,255,255,0.05)] backdrop-filter backdrop-blur-sm
                     hover:bg-[rgba(255,255,255,0.1)] focus:outline-none
                     transition duration-300 ease-in-out"
                                    >
                                        Subscribe
                                    </button>
                                </form>
                            </div>
                        </div>

                        <div className='border-b border-gray-800 my-10'></div>


                        <div className='flex justify-between items-center '>
                            <div className='flex items-center justify-center text-slate-400 gap-6 font-medium text-md'>
                                <h3>
                                    Privacy Policy
                                </h3>
                                <h3 className='text-center'>
                                    .
                                </h3>
                                <h3>
                                    Terms of Service
                                </h3>
                            </div>
                            <div className='text-slate-400'>
                                <p>© 2025 Digimation Flight. All rights reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='md:hidden w-full h-full bg-black py-10 '>
                <div className='container mx-auto px-4'>
                    <div className=' flex mb-4 '>
                        <img src={logo} alt='logo' className='w-11 h-11'/>
                        <h2 className='py-2 px-2 text-white font-medium text-xl font-[inter]'>Digimation Flight</h2>
                    </div>
                    <div className='flex items-center my-10 gap-5 '>
                        <form className="flex gap-6">
                            <input
                                type="email"

                                placeholder="Enter your email"
                                className=" flex-grow w-1/2 px-3 py-2 rounded-md bg-transparent text-gray-300 placeholder-gray-500 border border-gray-800 focus:outline-none focus:border-gray-700"
                                required
                            />
                            <button
                                type="submit"
                                className="px-4 py-2 rounded-md text-gray-300
                     bg-[rgba(255,255,255,0.05)] backdrop-filter backdrop-blur-sm
                     hover:bg-[rgba(255,255,255,0.1)] focus:outline-none
                     transition duration-300 ease-in-out"
                            >
                                Subscribe
                            </button>
                        </form>
                    </div>
                    <div className='border-b-2 my-10 border-white'/>
                    <div className='flex flex-col my-10'>

                        <div className='flex flex-col gap-6 text-left my-5 '>
                            <h3 className='text-white font-montserrat  text-2xl font-bold'>Quick Links</h3>
                            <Link to='/' className=' font-montserrat text-white text-xl font-semibold'>Home </Link>
                            <Link to='/about' className='font-montserrat text-white text-xl font-semibold'>About </Link>
                            <Link to='/service'
                                  className='font-montserrat text-white text-xl font-semibold'>Service</Link>
                            <Link to='/blogs' className='font-montserrat text-white text-xl font-semibold'>Blog</Link>
                            <Link to='/contact'
                                  className='font-montserrat text-white text-xl font-semibold'>Contact</Link>
                        </div>
                        <div className='my-5'>
                            <h3 className='text-white font-montserrat  text-2xl font-bold my-5'>Join us</h3>
                            <div className='flex gap-5 items-end'>
                                <a href='https://www.instagram.com/digimationflight' target='_self'>
                                    <FaInstagram className='text-slate-300 w-8 h-8 '/>
                                </a>
                                <a href='https://www.linkedin.com/company/digimationflight/'
                                   target='_self'>
                                    <FaLinkedin className='text-slate-300 w-8 h-8 '/>
                                </a>
                                <a href='https://wa.me/917017768577' target='_self'>
                                    <FaWhatsapp className='text-slate-300 w-8 h-8 '/>
                                </a>
                            </div>
                        </div>
                        <div className='border-b-2 my-10 border-white'/>
                        <div className='text-white'>
                            <p>© 2025 Digimation Flight. All rights reserved.</p>
                        </div>
                    </div>


                </div>
            </div>

        </>
    );
};

export default Footer;