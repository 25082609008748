import React, {useEffect, useState} from 'react';
import {EyeIcon, EyeOffIcon} from 'lucide-react'
import {ReactComponent as Grp1} from "../../assets/image/Group 81.svg";
import {ReactComponent as Grp2} from "../../assets/image/Group 82.svg";
import {useNavigate} from "react-router-dom";

//Redux
import {useDispatch, useSelector} from "react-redux";
import {signIn, signUP} from "../../redux/slice/authSlice";


const Auth = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [activeTab, setActiveTab] = useState('login');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState(null);
    const [userData, setUserData] = useState({
        userName: '',
        email: '',
        password: ''
    })
    const handleShowPassword = () => setShowPassword(!showPassword);
    const handleChange = ( e ) => {
        setUserData({...userData, [e.target.name]: e.target.value})
    }
    const handleLogin = async ( e ) => {
        e.preventDefault();
        await dispatch(signIn(userData))
        if (localStorage.getItem('token')) {
            navigate('/')
            window.location.reload();
        }
    }
    const auth = useSelector((state) => state.auth)
    useEffect(() => {
        if(auth.error){
            setError(auth.error)
        }
    }, [auth]);

    const handleRegister = async ( e ) => {
        e.preventDefault();
        const result = await dispatch(signUP(userData));
        if (signUP.fulfilled.match(result)) {
            // Navigate to verify OTP page if registration is successful
            navigate('/verifyOtp', {
                state: {
                    email: userData.email
                }
            });
        }
    }

    return (
        <>
            <div className='bg-gray-100'>
                <div className='flex flex-col bg-gray-100  '>
                    <div className='flex justify-center items-center mt-20 mx-auto  '>
                        <div className='flex flex-col rounded-2xl  bg-white p-10 '>
                            <div className='mb-2   '>
                                <h1 className='text-center font-montserrat font-semibold text-2xl '>
                                    Welcome to Digimation Flight
                                </h1>
                            </div>
                            <div
                                className={`flex rounded-full mb-2  p-1 ${activeTab === 'register' ? `bg-flight-500` : 'bg-black'} text-white `}>
                                <button
                                    className={`flex-1 py-2 px-4 rounded-full ${
                                        activeTab === 'login' ? 'bg-yellow-400 ease-in-out duration-300 ' : ''
                                    }`}
                                    onClick={() => setActiveTab('login')}
                                >
                                    Login
                                </button>
                                <button
                                    className={`flex-1 py-2 px-4 rounded-full ${
                                        activeTab === 'register' ? 'bg-black ease-in-out duration-300' : ''
                                    }`}
                                    onClick={() => setActiveTab('register')}
                                >
                                    Register
                                </button>
                            </div>

                            <h6 className='font-montserrat text-left text-lg font-bold my-3'>
                                {activeTab === 'login' ? 'Login to continue Browsing' : ' Register to view our company in brief!!!'}
                            </h6>

                            {activeTab === 'login' && (
                                <form onSubmit={handleLogin}>
                                    <div className='mt-3'>
                                        <label htmlFor='email'
                                               className=' text-lg font-medium font-montserrat py-2 block '>
                                            Email
                                        </label>
                                        <input
                                            className=' w-full py-2 px-3 border border-blue-500 rounded-full  focus:outline-none focus:ring-1 focus:ring-flight-400'
                                            type='email'
                                            value={userData.email}
                                            onChange={handleChange}
                                            id='email'
                                            name='email'
                                            placeholder='Enter Your Email'
                                        />
                                    </div>
                                    <div className='mt-3'>
                                        <label htmlFor='password'
                                               className='text-lg font-medium font-montserrat py-2 block'>
                                            Password
                                        </label>
                                        <div className='relative'>
                                            <input
                                                className='w-full py-2 px-3 border border-blue-500 rounded-full  focus:outline-none focus:ring-1 focus:ring-flight-400'
                                                type={showPassword ? 'text' : "password"}
                                                value={userData.password}
                                                onChange={handleChange}
                                                id='password'
                                                name='password'
                                                placeholder='Enter Your Password'
                                            />
                                            <button
                                                type="button"
                                                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                                                onClick={handleShowPassword}
                                            >
                                                {showPassword ? (
                                                    <EyeOffIcon className="h-5 w-5 text-gray-400"/>
                                                ) : (
                                                    <EyeIcon className="h-5 w-5 text-gray-400"/>
                                                )}
                                            </button>
                                        </div>
                                    </div>

                                    <div className='mt-10 flex justify-end items-center '>
                                        <button
                                            className='bg-flight-500 text-black py-2 px-4 rounded-full w-52 hover:bg-flight-600'>
                                            Login
                                        </button>
                                    </div>
                                    {error && (
                                        <div className="mt-3 text-red-500 text-sm">
                                            {error.message || 'Registration failed. Please try again.'}
                                        </div>
                                    )}
                                </form>
                            )}
                            {activeTab === 'register' && (
                                <form onSubmit={handleRegister}>
                                    <div className='mt-3'>
                                        <label htmlFor='userName'
                                               className=' text-lg font-medium font-montserrat py-2 block '>
                                            UserName
                                        </label>
                                        <input
                                            className=' w-full py-2 px-3 border border-blue-500 rounded-full  focus:outline-none focus:ring-1 focus:ring-flight-400'
                                            type='userName'
                                            value={userData.userName}
                                            onChange={handleChange}
                                            id='userName'
                                            name='userName'
                                            placeholder='Enter Your UserName'
                                        />
                                    </div>
                                    <div className='mt-3'>
                                        <label htmlFor='email'
                                               className=' text-lg font-medium font-montserrat py-2 block '>
                                            Email
                                        </label>
                                        <input
                                            className=' w-full py-2 px-3 border border-blue-500 rounded-full  focus:outline-none focus:ring-1 focus:ring-flight-400'
                                            type='email'
                                            value={userData.email}
                                            onChange={handleChange}
                                            id='email'
                                            name='email'
                                            placeholder='Enter Your Email'
                                        />
                                    </div>
                                    <div className='mt-3'>
                                        <label htmlFor='password'
                                               className='text-lg font-medium font-montserrat py-2 block'>
                                            Password
                                        </label>
                                        <div className='relative'>
                                            <input
                                                className='w-full py-2 px-3 border border-blue-500 rounded-full  focus:outline-none focus:ring-1 focus:ring-flight-400'
                                                type={showPassword ? 'text' : "password"}
                                                value={userData.password}
                                                onChange={handleChange}
                                                id='password'
                                                name='password'
                                                placeholder='Enter Your Password'
                                            />
                                            <button
                                                type="button"
                                                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                                                onClick={handleShowPassword}
                                            >
                                                {showPassword ? (
                                                    <EyeOffIcon className="h-5 w-5 text-gray-400"/>
                                                ) : (
                                                    <EyeIcon className="h-5 w-5 text-gray-400"/>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                    {error && (
                                        <div className="mt-3 text-red-500 text-sm">
                                            {error.message || 'Registration failed. Please try again.'}
                                        </div>
                                    )}

                                    <div className='mt-10 flex justify-end items-center '>
                                        <button className='bg-flight-500 text-black py-2 px-4 rounded-full w-52 focus:outline-none focus:ring-2 focus:ring-orange-500 disabled:opacity-50 disabled:cursor-not-allowed ' disabled={auth.loading}>
                                            {auth.loading ? 'Signing Up...' : 'Sign Up'}
                                        </button>
                                    </div>
                                </form>
                            )}

                        </div>
                    </div>
                </div>

                <div className='flex items-end justify-between  bg-gray-100 '>
                    <Grp1 className='rotate-180 '/>
                    <Grp2 className='w-60 h-60  '/>
                </div>
            </div>

        </>
    );
};

export default Auth;