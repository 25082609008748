import React from 'react';
import {ReactComponent as Hero} from '../../assets/image/heroSection.svg'
import image from '../../assets/image/1.svg'

const HeroSection = () => {
    return (
        <>
            <div className='hidden lg:flex bg-hero bg-cover bg-center bg-no-repeat h-[600px] w-full'>
                <div className='flex justify-between lg:w-full items-center mx-auto container  '>
                    <section className=' font-montserrat flex flex-col  p-12 lg:max-w-[750px]'>
                        <h1 className='text-4xl leading-[56px] text-flight-500 font-extrabold tracking-wider font-montserrat text-pretty '> TURNING
                            DREAMS INTO CAREERS </h1>
                        <p className='text-2xl leading-[34px] font-montserrat font-semibold my-5'>Transforming the
                            future with innovative AI services, digital marketing, and hands-on training programs.</p>
                        <span className='flex gap-4 '>
                        <button
                            className=' w-52 text-center font-medium text-white border-2 border-flight-500 px-5 py-3  bg-black rounded-xl'>Explore Our Services</button>
                        <button
                            className='w-48 text-center font-bold text-black bg-flight-500 px-10 py-3 rounded-xl drop-shadow-xl shadow-flight-400 '>Get Started</button>
                    </span>
                    </section>
                    <div className='flex justify-center '>
                        <Hero className='w-3/5'/>
                    </div>
                </div>
            </div>
            <div className='
            flex flex-col items-center justify-center  md:hidden
            ' >
                <section className='flex flex-col items-center justify-center pt-10 container mx-auto px-4'>
                    <h1 className='text-3xl leading-10  text-flight-500 font-extrabold tracking-wider font-montserrat text-pretty text-center'> TURNING
                        DREAMS INTO CAREERS </h1>
                    <p className='text-2xl  font-montserrat font-semibold my-5 text-center text-pretty '>Transforming the
                        future with innovative AI services, digital marketing, and hands-on training programs.</p>
                    <span className='flex gap-2 my-9 '>
                        <button
                            className=' w-48 text-center font-medium text-white border-2 border-flight-500 px-2 py-3  bg-black rounded-xl'>Explore Our Services</button>
                        <button
                            className='w-48 text-center font-bold text-black bg-flight-500 px-10 py-3 rounded-xl drop-shadow-xl shadow-flight-400 '>Get Started</button>
                    </span>
                </section>
                <div className=' '>
                    <img src={image} alt='hero' className='w-80'/>
                </div>
            </div>
        </>
    );
};

export default HeroSection;