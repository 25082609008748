import {configureStore} from "@reduxjs/toolkit";

import authReducer from "./slice/authSlice";
import userReducer from './slice/userSlice';
import subscribeReducer from './slice/subscribe.slice'
const store = configureStore({
    reducer: {
        auth: authReducer,
        user: userReducer,
        subscribe: subscribeReducer
    }
})
export default store;