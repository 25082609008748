import './App.css';
import {useEffect} from "react";
import {Route, Routes} from "react-router-dom";

//HOC
import HomeHOC from "./HOC/Home.HOC";

//Pages
import HomePage from "./pages/HomePage";

//Components
import about from "./components/about";
import Services from "./components/services";
import Training from "./components/training";
import MasterClass from "./components/masterClass";
import Contact from "./components/contact";
import Blog from "./components/blog";
import auth from "./components/auth";
import Custom404 from "./components/404";

//REDUX
import {useDispatch} from "react-redux";
import {fetchUser} from "./redux/slice/userSlice";
import VerifyOtp from "./components/auth/verifyOtp";

function App() {
    const dispatch = useDispatch();

    useEffect(() => {
        if (localStorage.getItem('token')) {
            dispatch(fetchUser())
        }
    }, [dispatch]);
    return (
        <>
            <Routes>
                <Route path="/" element={<HomeHOC component={HomePage}/>}/>
                <Route path="/about" element={<HomeHOC component={about}/>}/>
                <Route path="/service" element={<HomeHOC component={Services}/>}/>
                <Route path="/training" element={<HomeHOC component={Training}/>}/>
                <Route path="/masterClass" element={<HomeHOC component={MasterClass}/>}/>
                <Route path="/contact" element={<HomeHOC component={Contact}/>}/>
                <Route path="/blogs" element={<HomeHOC component={Blog}/>}/>
                <Route path="/auth" element={<HomeHOC component={auth}/>}/>
                <Route path="/verifyOtp" element={<HomeHOC component={VerifyOtp}/>}/>

                <Route path="*" element={<Custom404/>}/>

            </Routes>


        </>
    );
}

export default App;
