import React from 'react';
import aboutus from '../assets/image/aboutUs.png'
import purpose from '../assets/image/Purposeful man.png'
import LightOn from '../assets/image/Light On.png'

const About = () => {
    return (
        <>
            <div className='md:hidden w-full'>
                <div className='object-cover'>
                    <img src={aboutus} alt='aboutus' className='w-full h-full'/>
                </div>
                <div className='bg-white w-full h-full'>
                    <div className='container mx-auto px-12  flex flex-col justify-center '>
                        <div className='flex items-center gap-2 '>
                            <h2 className='text-[25px] font-bold font-montserrat '>About Digimation Flight</h2>
                            <img src={purpose} alt='purpose' className='w-7 h-7 '/>
                        </div>
                        <div className='my-5 flex justify-center items-center'>
                            <p className='text-black font-montserrat font-medium text-sm text-pretty'>Detailed mission
                                statement focusing on <br/> simplifying business processes and providing
                                industry-relevant
                                skills.</p>
                        </div>

                        <div className='my-5 flex items-center  gap-2'>
                            <h2 className='text-[25px] font-semibold font-montserrat'>Our Mission</h2>
                            <img src={LightOn} alt='Light' className='w-7 h-7'/>
                        </div>
                        <div className='mb-5 flex justify-center items-center'>
                            <p className='text-[#050505] font-montserrat  text-sm text-pretty'>Aiming to
                                bridge the gap between what students need and what businesses demand.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default About;