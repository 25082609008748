import React from 'react';
import box from '../assets/image/Rectangle 34624118.svg'
import {ReactComponent as Ai} from "../assets/image/7.svg";
import {ReactComponent as Digi} from "../assets/image/8.svg";
import {ReactComponent as Train} from "../assets/image/trainingSVG.svg";

const Services = () => {
    return (
        <>
            <div className='flex flex-col'>
                <div className='relative flex justify-center items-center '>
                    <img src={box} alt='box' className='w-full md:h-[600px] h-[500px] object-cover '/>
                    <div className='absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 '/>
                    <h1 className=' absolute text-balance text-center text-white text-3xl  md:text-5xl align-middle font-semibold font-montserrat  bg-transparent '>
                        AI innovation, digital marketing expertise, and top-notch training—all in one integrated
                        experience.
                    </h1>

                    <button
                        className='bg-flight-500 text-black py-5 px-10 rounded-xl font-semibold text-xl absolute font-[inter] md:top-[450px] top-[350px] shadow-amber-200 shadow-md '>Connect
                        with Us
                    </button>
                </div>
                <div className='mx-auto container py-10 '>
                <div className=' flex flex-col gap-1'>
                        <h1 className='text-center text-4xl font-montserrat font-bold'>
                            Our Services
                        </h1>
                        <p className=' text-center md:text-3xl text-xl font-semibold font-montserrat '>
                            designed to drive efficiency and creativity
                        </p>
                    </div>
                    <div className='flex  items-center flex-col '>
                        <div className=' mx-10 my-10 max-w-96 shadow-xl drop-shadow-md md:max-w-[1149px] p-10 bg-[#FFF9FA] border border-black rounded-xl'>
                            <div
                                className=' flex  '>
                                <div className=' flex flex-col gap-1'>
                                    <h2 className='md:text-4xl text-3xl font-semibold font-montserrat  '>
                                        Ai Services
                                    </h2>
                                    <ul className='md:list-disc md:pl-6 space-y-2 font-montserrat '>
                                        <li className='md:text-2xl text-lg font-medium leading-7 md:leading-9 mt-2 '>
                                            AI Business Consultation: Helping businesses automate tasks with tailored AI
                                            tools.
                                        </li>
                                        <li className='md:text-2xl text-lg font-medium leading-9'>
                                            AI Roop Advertising: Affordable celebrity marketing using AI-generated
                                            avatars.

                                        </li>
                                        <li className='md:text-2xl text-lg font-medium leading-9'>
                                            AI Training for Businesses: Equipping teams with AI knowledge for better
                                            efficiency.
                                        </li>
                                    </ul>
                                <Ai className='md:hidden w-72 h-72 bg-transparent my-2'/>
                                </div>
                                <Ai className='hidden md:flex w-80 h-80 bg-transparent'/>
                            </div>
                            <div className='flex items-center justify-center my-2'>
                                <button
                                    className='bg-flight-500 text-white py-5 px-10 rounded-xl font-medium md:text-xl text-lg font-montserrat border border-black
                                    hover:bg-flight-600

                                     '>
                                    Discover Our AI Solutions
                                </button>
                            </div>
                        </div>
                        <div className='  mx-10 my-10 max-w-96 shadow-xl drop-shadow-md md:max-w-[1149px] p-10 bg-[#FFF9FA] border border-black rounded-xl'>
                            <div
                                className='  flex gap-10 '>
                                <Digi className=' hidden md:flex  w-80 h-80 bg-transparent mx-2'/>
                                <div className=' flex flex-col gap-1'>
                                    <h2 className='md:text-4xl text-3xl font-semibold font-montserrat  '>
                                        Digital marketing solutions
                                    </h2>
                                    <ul className='md:list-disc md:pl-6 space-y-2 font-montserrat '>
                                        <li className='md:text-2xl text-lg font-medium leading-7 md:leading-9 mt-2'>
                                            SEO: Improves search engine ranking with keyword and technical
                                            optimizations.
                                        </li>
                                        <li className='md:text-2xl text-lg font-medium leading-7 md:leading-9 mt-2'>
                                            Content Marketing: Creates engaging content to attract and retain customers.

                                        </li>
                                        <li className='md:text-2xl text-lg font-medium leading-7 md:leading-9 mt-2'>
                                            Social Media Management: Manages profiles to boost brand presence and engage
                                            with audiences.
                                        </li>
                                    </ul>
                                    <Digi className='md:hidden  w-72 h-72 my-5 bg-transparent mx-2'/>

                                </div>

                            </div>
                            <div className='flex items-center justify-center my-2'>
                                <button
                                    className='bg-flight-500 text-white py-5 md:px-10 px-3 rounded-xl font-medium md:text-xl text-lg font-montserrat border border-black '>
                                    Boost Your Online Presence
                                </button>
                            </div>
                        </div>
                        <div className='mx-10 my-10 max-w-96 shadow-xl drop-shadow-md md:max-w-[1149px] p-10 bg-[#FFF9FA] border border-black rounded-xl'>
                            <div
                                className='  flex  '>
                                <div className=' flex flex-col gap-1'>
                                    <h2 className='md:text-4xl text-3xl font-semibold font-montserrat  '>
                                        Training </h2>
                                    <ul className='md:list-disc md:pl-6 space-y-2 font-montserrat '>
                                        <li className='md:text-2xl text-lg font-medium leading-7 md:leading-9 mt-2'>
                                            AI/ML: Develops models and algorithms for machine learning.
                                        </li>
                                        <li className='md:text-2xl text-lg font-medium leading-7 md:leading-9 mt-2'>
                                            Cybersecurity: Protects systems and data from cyber threats.

                                        </li>
                                        <li className='md:text-2xl text-lg font-medium leading-7 md:leading-9 mt-2'>
                                            Web Development: Builds and maintains websites.

                                        </li>
                                        <li className='md:text-2xl text-lg font-medium leading-7 md:leading-9 mt-2'>
                                            Digital Marketing: Promotes brands online through various strategies.

                                        </li>
                                        <li className='md:text-2xl text-lg font-medium leading-7 md:leading-9 mt-2'>
                                            AI Prompt Engineering: Designs prompts to guide AI interactions.

                                        </li>
                                    </ul>
                                    <Train className='md:hidden w-72 h-72 my-5 bg-transparent'/>
                                </div>
                                <Ai className='hidden md:block w-80 h-80'/>
                            </div>
                            <div className='flex items-center justify-center my-3'>
                                <button
                                    className='bg-flight-500 text-white py-5 px-10 rounded-xl font-medium md:text-xl text-lg font-montserrat border border-black '>
                                    Start Learning Today
                                </button>
                            </div>
                        </div>
                    </div>


                </div>

                <div className='bg-[#FFF9FA] md:p-10 '>
                    <div className='mx-auto container'>
                        <div className='flex-col flex justify-center items-center gap-2'>
                            <h1 className='md:text-4xl text-2xl text-center font-semibold font-montserrat md:leading-none leading-8 '>
                                Join us and create your digital success story!                     </h1>

                            <span className='md:text-2xl text-xl font-medium text-center font-montserrat py-5 '>Discover Your Digital Future Today</span>
                            <div className='flex justify-center items-center gap-5 py-10 '>
                                <button
                                    className='bg-[#FFF9FA] border border-black py-5 px-7 rounded-2xl font-montserrat hover:bg-black hover:text-[#FFF9FA] '>
                                    Get Started
                                </button>
                                <button
                                    className=' bg-black  text-[#FFF9FA] py-5 px-7 border border-black  rounded-2xl font-montserrat hover:bg-[#FFF9FA] hover:text-black  '>
                                    Free Consultation
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Services;