import React from 'react';
import certificate from "../assets/image/certificate.png";

const Certificate = () => {
    return (
        <>
          <div className='bg-black pt-20 '>
              <div className='flex flex-col  container mx-auto py-10' >
                  <div className=' text-2xl md:text-4xl text-white font-montserrat font-semibold text-center'>
                      Get Certificate Course
                  </div>
                  <div className=' flex items-center justify-center my-10 px-10'>
                      <img src={certificate} alt="certificate" className=' object-cover' />
                  </div>
              </div>

          </div>
        </>
    );
};

export default Certificate;