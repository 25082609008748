import React from 'react';
import eclipse from '../assets/image/Eclipse.png'
import Ai from '../assets/image/Ai (2).png';
import Digita from '../assets/image/digital.png';
import Train from '../assets/image/train.png'


const Service = () => {
    return (<>
        <div className='bg-black  font-montserrat '>
            <div className='hidden lg:flex justify-center flex-col mx-auto container p-10 '>
                <h1 className='text-center text-4xl font-semibold text-white tracking-wide py-10 '>Our Services</h1>
                <div className='flex justify-center items-center gap-20 '>
                    <div className=' border border-flight-400 rounded-2xl max-w-[350px] '>
                        <div className='px-10 py-16 '>
                            <h1 className=' font-bold text-center text-2xl text-white items-center pb-7  '>
                                AI services
                            </h1>
                            <ul className=' leading-6 text-xl h-auto font-medium text-flight-500 max-w-[200px]  '>
                                <li>AI Business Consultation</li>
                                <li>
                                    AI Roop Advertising
                                </li>
                                <li>
                                    AI Trainings for Businesses
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className=' border border-flight-400 rounded-2xl max-w-[350px] '>
                        <div className='px-10 py-16 '>
                            <h1 className=' font-bold text-center text-2xl text-white items-center pb-7  '>
                                Digital Marketing
                            </h1>
                            <ul className=' leading-6 text-xl h-auto font-medium text-flight-500 max-w-[250px]  '>
                                <li>SEO & SEM

                                </li>
                                <li>
                                    Social Media Marketing Content Marketing Email Marketing
                                </li>
                                <li>
                                    PPC Advertising.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className=' border border-flight-400 rounded-2xl max-w-[350px] '>
                        <div className='px-10 py-10 '>
                            <h1 className=' text-center font-bold text-2xl text-white items-center pb-7  '>
                                Training programs </h1>
                            <ul className=' leading-6 text-xl h-auto font-medium text-flight-500 max-w-[200px]  '>
                                <li>Training in :-


                                </li>
                                <li>
                                    AI/ML,
                                </li>
                                <li>
                                    Cybersecurity
                                </li>
                                <li>
                                    Web Development, Digital Marketing
                                </li>
                                <li>
                                    AI Prompt Engineering
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='md:hidden flex flex-col justify-center items-center h-auto ' style={{
                backgroundImage: `url(${eclipse})`,
                backgroundSize: 'cover',
                backgroundPosition: 'auto',
                backgroundRepeat: 'no-repeat',


            }}>
                <h1 className='text-center text-2xl font-semibold text-white tracking-wide py-5 '>
                    Our Services
                </h1>
                <p className='font-montserrat font-semibold text-lg text-center text-white'>
                    Get the <span className='text-flight-500'> Digimation Flight</span> advantage
                </p>
                <div className=' mt-5'>
                    <div className='py-5 flex flex-col justify-center items-center'>
                        <img src={Ai} alt='Ai' className=''/>
                        <h1 className='text-center text-white font-bold text-xl py-3 '>
                            AI services
                        </h1>
                        <ul className='text-flight-500  font-medium text-sm  '>
                            <li>AI Business Consultation</li>
                            <li>AI Roop Advertising</li>
                            <li>AI Trainings for Businesses</li>
                        </ul>
                    </div>
                    <div className='py-5 flex flex-col justify-center items-center'>
                        <img src={Digita} alt='Digital Marketing' className=''/>
                        <h1 className='text-center text-white font-bold text-xl py-3 '>
                            Digital Marketing
                        </h1>
                        <ul className='text-flight-500  font-medium text-sm  '>
                            <li>SEO & SEM</li>
                            <li className='w-52'>Social Media Marketing Content Marketing Email Marketing</li>
                            <li>PPC Advertising.</li>
                        </ul>
                    </div>
                    <div className='py-5 flex flex-col justify-center items-center'>
                        <img src={Train} alt='Training Programs' className=''/>
                        <h1 className='text-center text-white font-bold text-xl py-3 '>
                            Training programs
                        </h1>
                        <ul className='text-flight-500  font-medium text-sm  '>
                            <li>Training in :-
                            </li>
                            <li>AI/ML,</li>
                            <li>Cybersecurity</li>
                            <li> Web Development</li>
                            <li>Digital Marketing</li>
                            <li>AI Prompt Engineering</li>
                        </ul>
                    </div>
                </div>

            </div>

        </div>
    </>);
};

export default Service;